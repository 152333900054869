<template>
  <div class="auth-container">
    <div class="modal-login-title">Введите код</div>
    <div class="modal-login-subtitle">
      {{$route.query.phone ? `Номер телефона ${$route.query.phone}` : `Email ${$route.query.email}`}}
      <span class="color-blue" @click="$router.back()" style="cursor: pointer;"> (Ред.)</span>
    </div>
    <v-otp-input
      ref="otp_input"
      v-model="code"
      :length="4"
      plain
      type="number"
      @input="codeError = ''"
      autofocus
    />
    <span class="red--text otp-code-error" v-if="codeError">{{codeError}}</span>
    <div :class="{'transparent-button': true, 'disabled': timerValue}" @click="sendAgainClick">
      <template v-if="timerValue">
        {{ sendAgainStr }} ({{ timerValue }} сек)
      </template>
      <template v-else>
        {{ sendAgainStr }}
      </template>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  name: 'LoginCode',
  data: () => ({
    sendAgainStr: '',
    timerValue: null,
    codeToken: null,
    multipleConnectionProtection: false,
    code: '',
    codeError: '',
  }),
  methods: {
    timer() {
      const interval = setInterval(() => {
        this.timerValue -= 1;
        if (this.timerValue <= 0) {
          clearInterval(interval);
          this.timerValue = 0;
        }
      }, 1000);
    },
    sendAgainClick() {
      if (!this.timerValue && !this.multipleConnectionProtection) {
        const params = {};
        if (this.$route.query.phone) {
          params.phone = this.$route.query.phone;
          this.sendAgainStr = 'СМС отправлено ещё раз';
        } else if (this.$route.query.email) {
          params.email = this.$route.query.email;
          this.sendAgainStr = 'Письмо отправлено ещё раз';
        }
        this.multipleConnectionProtection = true;
        this.$server.request2('auth/login', params, (data)=>{
          this.codeToken = data.response;
          setTimeout(() => {
            this.timerValue = 30;
            if (this.$route.query.phone) {
              this.sendAgainStr = 'Не пришло СМС';
            } else if (this.$route.query.email) {
              this.sendAgainStr = 'Не пришло письмо';
            }
            this.timer();
            this.multipleConnectionProtection = false;
          }, 2000);
        })
      }
    },
    next() {
      if (this.checkValue) {
        this.$server.request2('auth/checkCode', {
          token: this.codeToken,
          code: this.code,
        }, (data)=>{
          if (window.location.host.indexOf('localhost:')===0) {
            localStorage.setItem('api_token', data.token);
          } else {
            // this.$server.setCookie('token_js', data.token, 10000);
          }
          window.location.href = window.location.origin + (this.$route.query.url || '');
        }, ()=>{
          this.codeError = 'Код неверный';
          setTimeout(() => {
            this.code = '';
            this.$refs.otp_input.focus();
          }, 2000);
        })
      }
    },
    prepareData() {
      this.$refs.otp_input.focus();
      this.codeToken = this.$route.query.codeToken;
      if (this.$route.query.phone) {
        this.title = 'Введите код из смс';
        this.sendAgainStr = 'Не пришо СМС';
        this.timerValue = 30;
      } else if (this.$route.query.email) {
        this.title = 'Отправлен код на почту';
        this.subtitle = `Email ${this.$route.query.email}`;
        this.sendAgainStr = 'Не пришло письмо';
        this.timerValue = 0;
      }
      this.timer();
    },
  },
  computed: {
    checkValue() {
      return this.code.length === 4;
    },
  },
  created() {

  },
  mounted() {
    this.prepareData();
  },
  watch: {
    checkValue(value) {
      if (value) this.next();
    },
    '$route.query.phone': function () {
      this.prepareData();
    },
  },
};
</script>

<style lang="scss">
.v-application.theme--light .v-otp-input .v-input {
  width: 30px;
  input {
    font-size: 22px;
    font-weight: bold;
  }
}
.otp-code-error {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}
</style>
